export const INTERNATIONAL_COUNTRIES = new Set([
    "AR",
    "BO",
    "BR",
    "CL",
    "CO",
    "CR",
    "CU",
    "DO",
    "FR",
    "IT",
    "GT",
    "HN",
    "IT",
    "MX",
    "NI",
    "PA",
    "PE",
    "PY",
    "US",
    "UY",
    "VE",
]);
